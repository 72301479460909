/* Import Google font - Poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');


*{
    font-family: 'Poppins', sans-serif;
    margin:0; padding:0;
    box-sizing: border-box;
    text-decoration: none;
    outline: none; border:none;
    text-transform: capitalize;
}


body{
    background:#f9f9f9;
    font-family: 'Manrope', sans-serif;
}

section{
    /* min-height: 100vh; */
    /* padding-top: 7.5rem; */
    padding:0 9%;
    padding-bottom: 2rem;
}
.bubby__footer_header-section{
   margin-top: 4rem;
   padding-top: 14rem;
}
.bubby__footer-home{
  margin-bottom: 0rem;
}
.footer-contact{
    font-size: 1.3rem;
    color: #40196D;
    font-weight: bolder;
}

.bubby__footer a:nth-last-child(1){
    margin-left: 1rem;
}
.bubby__footer-home{
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    position: relative;
}

.bubby__footer-home .image img{
    width:27vw;
    animation: float 3s linear infinite;
    margin-top: 0rem;
  
}

.bubby__footer-home .bubby__footer h3{
    font-size: 4rem;
    color:#40196D;
    text-transform: uppercase;
    width: 50%;
    font-weight: bold;
}


.bubby__footer-home .bubby__footer p{
    font-size: 1.5rem;
    color:rgb(10, 10, 10);
    padding:1rem 0;
    width: 50%;
    line-height: 30px;
    font-weight: 550;
}

.container {
  display: flex;
  /* padding: 0 10px; */
  min-height: 100vh;
  /* border: #40196D solid 1px; */
  align-items: center;
  /* justify-content: center; */
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}
  ::selection{
    color: #fff;
    background:#40196D;
  }
  .wrapper{
    width: 715px;
    background: #fff;
    border-radius: 5px;
  }
  .wrapper header{
    font-size: 22px;
    font-weight: 600;
    padding: 10px 20px;
    color: #40196D;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wrapper form{
    margin: 35px 30px;
  }
  .wrapper form.disabled{
    pointer-events: none;
    opacity: 0.7;
  }
  form .dbl-field{
    display: flex;
    margin-bottom: 25px;
    justify-content: space-between;
  }
  .dbl-field .field{
    height: 50px;
    display: flex;
    position: relative;
    width: calc(100% / 2 - 13px);
  }
  .wrapper form i{
    position: absolute;
    top: 50%;
    left: 18px;
    color: #40196D;
    font-size: 17px;
    pointer-events: none;
    transform: translateY(-50%);
  }
  form .field input,
  form .message textarea{
    width: 100%;
    height: 100%;
    outline: none;
    padding: 0 18px 0 48px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #40196D;
  }
  .field input::placeholder,
  .message textarea::placeholder{
    color: #40196D;
  }
  .field input:focus,
  .message textarea:focus{
    padding-left: 47px;
    border: 2px solid #40196D;
  }
  .field input:focus ~ i,
  .message textarea:focus ~ i{
    color: #40196D;
  }
  form .message{
    position: relative;
  }
  form .message i{
    top: 30px;
    font-size: 20px;
  }
  form .message textarea{
    min-height: 130px;
    max-height: 230px;
    max-width: 100%;
    min-width: 100%;
    padding: 15px 20px 0 48px;
  }
  form .message textarea::-webkit-scrollbar{
    width: 0px;
  }
  .message textarea:focus{
    padding-top: 14px;
  }
  form .button-area{
    margin: 25px 0;
    display: flex;
    align-items: center;
  }
  .button-area button{
    color: #fff;
    border: none;
    outline: none;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;
    padding: 13px 25px;
    background: #40196D;
    transition: background 0.3s ease;
  }
  .button-area button:hover{
    background: #40196D;
  }
  .button-area span{
    font-size: 17px;
    margin-left: 30px;
    display: none;
  }

  @media (max-width: 600px){
    .wrapper header{
      text-align: center;
    }
    .wrapper form{
      margin: 35px 20px;
    }
    form .dbl-field{
      flex-direction: column;
      margin-bottom: 0px;
    }
    form .dbl-field .field{
      width: 100%;
      height: 45px;
      margin-bottom: 20px;
    }
    form .message textarea{
      resize: none;
    }
    form .button-area{
      margin-top: 20px;
      flex-direction: column;
    }
    .button-area button{
      width: 100%;
      padding: 11px 0;
      font-size: 16px;
    }
    .button-area span{
      margin: 20px 0 0;
      text-align: center;
    }
  }


/* media queries  */
@media (max-width:1200px){
    html{
        font-size: 55%;
    }
    .container{
      width:auto
    }
    .bubby__footer-home .bubby__footer h3{
      width: 100%;
    }
    .bubby__footer-home .bubby__footer p{
      width: 100%;
    }
}

@media (max-width:991px){
    
    section{
        padding:0 3%;
        /* padding-top: 7.5rem; */
        padding-bottom: 2rem;
    }
}

@media (max-width:768px){
    .bubby__footer{
        margin-bottom: 10rem;
    }

    .bubby__footer-home .image img{
        width:100%;
    }

    .bubby__footer-home .bubby__footer h3{
        font-size: 3.6rem;
        width: 100%;
    }

    .bubby__footer-home .bubby__footer p{
        font-size: 1.5rem;
        width: 100%;
    }
    

}
@media (max-width:550px){
    .bubby__footer-home{
        flex-flow: column-reverse;
    }
    

    .bubby__footer-home{
        margin-top: -20rem;
    }
    .content{
        margin-top: 3rem;
    }
    .bubby__footer_header-section{
       margin-top: 12rem;
       padding-top: 14rem;
    }
    .container{
      margin-top: 0rem;
    }

    .bubby__footer{
      margin-bottom: 1rem;
  }
  
}

