@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');


*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}


body{
    background-color: rgb(255,255,255);
    font-family: 'Manrope', sans-serif;
}


.bubby__article {
    display: flex;
    flex-direction: column;    
   
}
.bubby__article-home{
    padding: 4rem 6rem;
}
.bubby__article-heading {
    width: 100%;
    margin-bottom: 5rem;
    text-align: center;
}

.bubby__article-heading h1 {
    font-size: 3rem;
    font-weight: 900;
    line-height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #40196D;
}
.buddy__article-Media{
    width: 100%;
    text-align: left;
    margin-bottom: 3rem;
}
.buddy__article-Media h2{
    font-size: 3rem;
    font-weight: 900;
    color: #40196D;
}
.bubby__article-heading p{
    font-size: 2rem;
    color: #000;
}
.bubby__article-heading p:nth-last-child(1){
    color: #40196D;
    font-size: 16px;
    font-weight: 700;
    margin-top: 1rem;
}
.bubby__article-container {
    display: flex;
    flex-direction: row;
}

.bubby__article-container_groupA {
    flex: 0.75;
    margin-right: 2rem;
}

.bubby__article-container_groupB {
    flex: 1;

    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
}

.bubby__cta{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 2rem;
    margin: 4rem;
    border-radius: 1rem;
    background: #fff;

}

.bubby__cta-content {
    display: flex;
    flex-direction: column;
    text-align: left;
    color: #40196D;
}

.bubby__cta-content p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 30px;
    font-weight: 500;
}

.bubby__cta-content h3 {
    font-family: var(--font-family);
    font-size: 24px;
    line-height: 45px;
    font-weight: 800;
}

.bubby__cta-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 2rem;
}

 .bubby__cta-btn button {
    background: #F4150C;;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    padding: 0.5rem 1rem;
    border-radius: 2rem;

    border: none;
    outline: none;
    cursor: pointer;
    min-width: 150px;
}

/* responsiveness */

@media screen and (max-width: 650px) {
    .bubby__cta{
        flex-direction: column;
    }

    .bubby__cta-btn {
        margin: 2rem 0 0;
    }
}

@media screen and (max-width: 550px) {
    .bubby__cta{
        flex-direction: column;
        margin: 4rem 2rem;
    }

    .bubby__cta-content h3 {
        font-size: 18px;
        line-height: 32px;
    }

    .bubby__cta-btn button {
        font-size: 14px;
        line-height: 28px;
    }
}

@media screen and (max-width: 990px) {
    .bubby__article-container {
        flex-direction: column-reverse;
    }


    .bubby__article-container_groupA {
        margin: 2rem 0;
    }

    .bubby__article-container_groupA .bubby__article-container_article  {
       width: 48%;
    }

    .gpt3__blog-container_groupA .gpt3__blog-container_article-image {
        height: 250px;
    }
}

@media screen and (max-width: 700px) {
    .bubby__article-container_groupB {
        grid-template-columns: repeat(1, 1fr);
    }

    .bubby__article-container_groupA .bubby__article-container_article  {
       width: 100%;
    }

    .bubby__article-heading h1 {
        font-size: 46px;
        line-height: 52px;
    }
}

@media screen and (max-width: 550px) {
    .bubby__article-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

}