
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}


body{
    background-color: rgb(255,255,255);
    font-family: 'Manrope', sans-serif;
}


.bubby__blog-container_article {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background:#40196D;
}

.bubby__blog-container_article-image {
    width: 100%;
    height: 100%;
    background: #40196D;
}

.bubby__blog-container_article-image img {
    width: 100%;
    height: 100%;
}

.bubby__blog-container_article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 1rem 1.5rem;
    height: 100%;
}

.bubby__blog-container_article-content p {
    font-family:inherit;
    font-size: 11.65px;
    font-weight: 700;
    line-height: 35px;

    color: #fff;
}

.bubby__blog-container_article-content h3 {
    font-family:inherit;
    font-size: 25.11px;
    font-weight: 800;
    line-height: 30.30px;
    
    color: #fff;
    margin-bottom: 5rem;
    cursor: pointer;
}

.bubby__blog-container_article-content p:last-child {
    cursor: pointer;
}

/* responsiveness */
@media screen and (max-width: 550px) {
    .bubby__blog-container_article-content h3 {
        font-size: 18px;
        line-height: 25p;
    }
}