@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');


*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}


body{
    background-color: rgb(255,255,255);
    font-family: 'Manrope', sans-serif;
}

 .services{
      background: #f1f1f1;
      text-align: center;
 }

.services h1{
      display: inline-block;
      text-transform: uppercase;
      border-bottom: 4px solid #40196D;
      font-size: 20px;
      padding-bottom: 10px;
      margin-top: 5rem;
      margin-bottom: 3rem;
   }
   .cen{
      max-width: 1200px;
      margin: auto;
      overflow: hidden;
      padding: 20px;
   }
  .service{
      display: inline-block;
      width: calc(100% / 3);
      margin: 0 -2px;
      padding: 20px;
      box-sizing: border-box;
      cursor: pointer;
      transition: 0.4s;
    }
    .service:hover{
      background: #ddd;
    }
    .service i{
      color: #40196D;
      font-size: 34px;
      margin-bottom: 30px;
    }
    .service h2{
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 500;
      margin: 0;
    }
    .service p{
      color: gray;
      font-size: 13px;
      font-weight: 500;
    }

    /* User form details */
.form-searchs{
  margin: 0 auto;
  text-align: center;
  font: bold 13px sans-serif;
   max-width: 70%;
  position: relative;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.form-searchs input{
  width: 60%;
  background-color:  #ffffff;
  padding: 14px 15px 14px 40px;
  border: 1px solid #b6c3cd;;
  border-right: 0;
  color:#4E565C;
  outline: none;
  -webkit-appearance: none;
}

  .form-searchs .btn-search{
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    background-color:#40196D;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.08);
    color: #ffffff;
    padding: 15px 22px;
    margin-left: -4px;
    cursor: pointer;
    border: none;
    outline: none;
  }
    @media screen and (max-width: 800px) {
      .service{
        width: 50%;
      }
    }
    @media screen and (max-width: 500px) {
      .service{
        width: 100%;
      }
    }
  
    @media screen and (max-width:600px){
      form{
        margin-left: 0;
      }
      input[type=text]{
        width: 100%;
        border-radius: 5px;
        padding: 10px;
      }
      input[type=submit]{
        border-radius: 5px;
        width: 100%;
        position: static;
        margin-top: 5px;
        padding: 10px;
        
      }
      
  }

  .support{
    background: #40196D;
    /* margin-bottom: 5rem; */
}

.support .row{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 3rem;
}

.support .row .image{
    flex:1 1 45rem;
    text-align: center;
}

.support .row .image img{
    width:85%;
    border-radius: .5rem;
}

.support .row .content{
    flex:1 1 45rem;
    padding: 2.5rem;
}

.support .row .content h3{
    font-size: 3.5rem;
    color:#fff;
    text-transform: cap;
}

.support .row .content p{
    font-size: 1.8rem;
    color:#eee;
    padding:1rem 0;
}

.support .row .content p i{
    padding-right: .5rem;
    color:#637CBB;
}

.list-btn{
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  margin: auto;
  align-items: center;
  margin-bottom: 4rem;
  margin-top: 1.1rem;
}
/* .flex-list-btn{
  display: flex;
} */
.list-btn p{
  font-size: 14px;
  align-items: center;
  /* margin: 2rem; */
  display: inline-block;
  border: solid 1px #40196D;
  border-radius: 5rem;
  transition: all .3s linear;
  padding:.8rem 1rem;
}
@media (max-width:768px){
  .list-btn p{
    margin: 2rem;
    font-size: 13px;
    margin-bottom: 0rem;
  }
  .list-btn{
    width: 100%;
  }
}

@media (max-width: 550px){
    .list-btn {
      display: flex;
      flex-wrap: wrap;
    }
}