
*{
    font-family: 'Poppins', sans-serif;
    margin:0; padding:0;
    box-sizing: border-box;
    text-decoration: none;
    outline: none; border:none;
    text-transform: capitalize;
}


html{
    font-size: 62.5%;
    overflow-x: hidden;
}

body{
    background:#f9f9f9;
}

section{
    padding:0 9%;
    padding-top: 7.5rem;
    padding-bottom: 2rem;
}

.btn{
    display: inline-block;
    margin-top: 1rem;
    padding:.8rem 3rem;
    border-radius: 5rem;
    background: #F4150C;
    font-size: 1.7rem;
    color:#fff;
    cursor: pointer;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.1);
    transition: all .3s linear;
    position: relative;
}
/* .social{
    display: flex;
    transform: rotate(-90deg);
    position: absolute;
    right: 0;
    left: 90%;
}

.social > a:not(:last-child) {
    margin-right: 30px;
    color: #fff;
    font-weight: 900;
    font-size:  18px;
} */

/* .social > a:nth-last-child(1){
    color: #fff;
    font-weight: 900;
    word-spacing: 2px;
    font-size:18px;
} */

.btn:hover{
    transform: scale(1.1);
    background-color:#524685;
    color: #fff;
}

.bubby__home {
    display: flex;
    min-height: 100vh;
    justify-content: space-between;
    align-items: center;
    background:linear-gradient(rgba(46,46,46,0.8),rgba(46,46,46,0.9)),
    url(/src/assets/splash.png) no-repeat;
    background-size: cover;
}

.bubby__home .image img{
    width:33vw;
    animation: float 3s linear infinite;
    margin-top: 0rem;
}

@keyframes float{
    0%, 100%{
        transform: translateY(0rem);
    }
    50%{
        transform: translateY(-3.5rem);
    }
}

.bubby__home .bubby__content h3{
    font-size: 5.5rem;
    color:#fff;
    text-transform: uppercase;
    width: 60%;
    font-weight: bolder;
}


.bubby__home .bubby__content p{
    font-size: 1.7rem;
    color:#767676;
    padding:1rem 0;
    width: 60%;
}



/* media queries  */
@media (max-width:1200px){
    
    html{
        font-size: 55%;
    }

}

@media (max-width:991px){
    
    section{
        padding:0 3%;
        padding-top: 7.5rem;
        padding-bottom: 2rem;
    }

}

@media (max-width:768px){
   

    .bubby__home .image img{
        width:30rem;
    }

    .bubby__home .bubby__content h3{
        font-size: 3.6rem;
        width: 100%;
    }

    .bubby__home .bubby__content p{
        font-size: 1.5rem;
        width: 100%;
    }
    /* .social > a:not(:last-child) {
        font-size:  10px;
    }
    
    .social > a:nth-last-child(1){
        font-size: 10px;
    }
    .social {
        left: 97%;
        top: 40rem;
    } */
}

@media (max-width:550px){
    .bubby__home{
        flex-flow: column-reverse;
        justify-content: center;
    }
    html{
        font-size: 50%;
    }

    .bubby__home{
        height: 100vh;
    }
    .content{
        margin-top: 3rem;
    }
    .social{
        position: absolute;
        top: 20%;
        left: 30%;
        transform: none;
    }
    .bubby__home .bubby__content h3{
         width: 100%;
    }
    .bubby__home .bubby__content p{
        width: 100%;
    }
    /* .social > a:not(:last-child) {
        font-size:  8px;
    }
    
    .social > a:nth-last-child(1){
     
        font-size: 8px;
    } */
}

@media (max-width:320px){
    .bubby__home {
        height: 130vh;
    }
    .social {
        top: 125% !important;
    }
}


@media screen and (max-width: 1024px){

}